import React, { useState } from "react";
import {
    Box,
    Heading,
    Stack,
    Text,
    IconButton,
    Link,
    FormControl,
    FormLabel,
    Input,
    Button,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import { ExternalLinkIcon, LinkIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const InviteTeams = () => {
    const [emails, setEmails] = useState([""]);
    const navigate = useNavigate();

    const addEmailField = () => {
        setEmails([...emails, ""]);
    };

    const handleEmailChange = (index, value) => {
        const updatedEmails = [...emails];
        updatedEmails[index] = value;
        setEmails(updatedEmails);
    };

    const clearEmail = (index) => {
        const updatedEmails = [...emails];
        updatedEmails[index] = "";
        setEmails(updatedEmails);
    };

    return (
        <Box
            width="100%"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box width={{ base: "90%", md: "400px" }}>
                <Heading as="h1" size="lg" textAlign="start" mb={1}>
                    Invite Teammates
                </Heading>
                <Text fontSize="14">
                    Get the most out of Stash by inviting your teammates.
                </Text>
                <br />
                <Stack direction="row" justifyContent="space-between">
                    <Text>Send Invites</Text>
                    <Stack direction="row">
                        <Link>
                            Shareable Link <LinkIcon />{" "}
                        </Link>
                    </Stack>
                </Stack>
                {emails.map((email, index) => (
                    <FormControl key={index} mt={index > 0 ? 2 : 0}>
                        <InputGroup>
                            <Input
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => handleEmailChange(index, e.target.value)}
                            />
                            {email && (
                                <InputRightElement>
                                    <IconButton
                                        aria-label="Clear input"
                                        icon={<Text>X</Text>}
                                        onClick={() => clearEmail(index)}
                                        variant="link"
                                    />
                                </InputRightElement>
                            )}
                        </InputGroup>
                    </FormControl>
                ))}
                <Button mt={4} bg='white' onClick={addEmailField}>
                    <Text fontSize={20} mr={1}>+</Text> Add More
                </Button>

                <Button
                    type="submit"
                    width='full'
                    mt='4'
                    bg='gray.700'
                    color='white'
                    onClick={() => navigate('/proplan')}
                >
                    Take Me to Stash
                </Button>
                <Link textAlign="center" display="block" fontSize='13' fontWeight='bold' mt='5'>
                    Skip This Step
                </Link>
            </Box>
        </Box>
    );
};

export default InviteTeams;
