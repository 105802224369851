import React, {useEffect, useState} from 'react';
import {Box, Button, Heading, HStack, Select, Text, Textarea, VStack} from '@chakra-ui/react';
import MarkdownRenderer from "../../../../component/MarkdownRenderer";

function Compare({onBack, stash}) {
    const [llmPrompt, setLlmPrompt] = useState('');
    const [llmOutput, setLlmOutput] = useState([]);
    const [isLLMLoading, setIsLLMLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [source1, setSource1] = useState(null);
    const [source2, setSource2] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchSources = async () => {
            if (stash) {
                setIsLoading(true);
                try {
                    const response = await fetch(`https://stash-fn-dev.azurewebsites.net/api/GetSources?code=B3SIZvN8Yo0QC_ijH-cfhG4pp3i0io5Cdp7YrPTs4M0dAzFuDU4p_A%3D%3D&stashId=${stash.id}`, {
                        method: 'GET'
                    });
                    if (!response.ok) throw new Error('Failed to fetch uploadFiles');
                    const files = await response.json();
                    setFiles(files);
                    setIsLoading(false);
                } catch (error) {
                    setError(error.message);
                    setIsLoading(false);
                }
            }
        };
        fetchSources();
    }, [stash]);

    const handleLlmPromptChange = (event) => {
        setLlmPrompt(event.target.value);
    };

    const compare = async () => {
        if (!llmPrompt) {
            alert("Please enter a prompt.");
            return;
        }
        setIsLLMLoading(true);
        try {
            const response = await fetch('https://stash-fn-dev.azurewebsites.net/api/Compare?code=pkg5z90KyipmnacIyfJzBb29OSybCWql7hnVky-P7IOMAzFua4iQ8A%3D%3D', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({prompt: llmPrompt, source1: source1.id, source2: source2.id})
            });
            if (!response.ok) {
                throw new Error('Failed to compare sources');
            }
            const data = await response.json();
            setLlmOutput(data.result);
        } catch (error) {
            console.error('Failed to process LLM prompt', error);
            // setLlmOutput([{name: "Error", value: `Error: ${error.message}`, sources: {"sources1": [], "sources2": []}}]);
        } finally {
            setIsLLMLoading(false);
        }
    };

    const availableFiles1 = files.filter(f => f !== source2);
    const availableFiles2 = files.filter(f => f !== source1);

    const embedCodeSample = `
    <!-- Embed code for Stash Chat -->
    <script src="https://cdn.stashapp.com/stashLoader.js"></script>
    <script>
      loadStashCompare('123e4567-e89b-12d3-a456-426614174000', 'your_api_key_here')
    </script>
    `;

    return (
        <HStack alignItems={"start"} spacing={"24px"}>
            <Box borderWidth="1px" borderRadius="lg" p={4} width={"25%"}>
                <VStack alignItems={"start"} spacing={2}>
                    <Button onClick={onBack}>Back</Button>
                    <Heading as="h2" size="md">Configurations</Heading>
                    <Box>
                        <Text>Widget UI</Text>
                        <Text>Search Behavior</Text>
                        <Text>API Response</Text>
                        <Text>Embed Code</Text>
                    </Box>
                </VStack>
            </Box>
            <VStack spacing={4} align="stretch" width={"50%"}>
                <Heading as="h1" size="xl">Compare Sources</Heading>
                <Text>Choose the sources below that you want to compare.</Text>
                {error && (<Text color={"red.500"}>{error}</Text>)}
                {isLoading && (<Text color={"gray.500"}>Loading Files...</Text>)}
                <Select placeholder="Select Source 1" value={source1 ? source1.id : ''} onChange={(e) => setSource1(files.find(file => file.id === e.target.value))}>
                    {availableFiles1.map(file => (
                        <option key={file.id} value={file.id}>{file.name}</option>
                    ))}
                </Select>
                <Select placeholder="Select Source 2" value={source2 ? source2.id : ''} onChange={(e) => setSource2(files.find(file => file.id === e.target.value))}>
                    {availableFiles2.map(file => (
                        <option key={file.id} value={file.id}>{file.name}</option>
                    ))}
                </Select>
                <Textarea height={"300px"} placeholder="Enter LLM prompt here..." value={llmPrompt}
                          onChange={handleLlmPromptChange} size="md"/>
                <Button mt={2} colorScheme="blue" onClick={compare} isLoading={isLLMLoading}>Run</Button>
                <Box mt={2} p={4} border="1px solid" borderColor="gray.200">
                    <Text>Output:</Text>
                    <MarkdownRenderer content={llmOutput ? llmOutput : ""}/>
                </Box>
                <Heading as="h2" size="md">Embed</Heading>
                <MarkdownRenderer content={embedCodeSample}/>
            </VStack>
        </HStack>
    );
}

export default Compare;