import React, {useState} from 'react';
import {Box, Button, Grid, GridItem, Heading, HStack, IconButton, Input, Text, VStack} from '@chakra-ui/react';
import MarkdownRenderer from "../../../../component/MarkdownRenderer";
import {CloseIcon} from "@chakra-ui/icons";

function Extract({onBack, stash}) {
    const [llmOutput, setLlmOutput] = useState([]);
    const [isLLMLoading, setIsLLMLoading] = useState(false);
    const [inputItems, setInputItems] = useState([]);

    const handleAddItem = () => {
        setInputItems([...inputItems, {name: '', description: ''}]);
    };

    const handleInputChange = (index, field, value) => {
        const newItems = [...inputItems];
        newItems[index][field] = value;
        setInputItems(newItems);
    };

    const handleRemoveItem = (index) => {
        const newItems = inputItems.filter((_, i) => i !== index);
        setInputItems(newItems);
    };

    const extractFromData = async () => {
        if (inputItems.length === 0 || inputItems.some(item => !item.name)) {
            alert("Please enter at least one item with a name.");
            return;
        }

        setIsLLMLoading(true);

        try {
            const response = await fetch('https://stash-fn-dev.azurewebsites.net/api/ExtractFromData?code=33bLstyoiN4EIp4RJvoVAznmsOYnfmpyIodK-7k2aNtiAzFuyPzYzA%3D%3D', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({stashId: stash.id, items: inputItems})
            });

            if (!response.ok) {
                throw new Error('Failed to chat with data');
            }

            const data = await response.json();
            const mappings = data.result.mappings;

            // Create a map of item names to their indices
            const nameIndexMap = new Map(inputItems.map((item, index) => [item.name, index]));

            // Sort mappings based on the order of inputItems
            const sortedResults = mappings.sort((a, b) => {
                return nameIndexMap.get(a.name) - nameIndexMap.get(b.name);
            });

            setLlmOutput(sortedResults);
        } catch (error) {
            console.error('Failed to process LLM prompt', error);
            setLlmOutput([{ name: "Error", value: `Error: ${error.message}`, sources: [] }]);
        } finally {
            setIsLLMLoading(false);
        }
    };

    const embedCodeSample = `
    <!-- Embed code for Stash Chat -->
    <script src="https://cdn.stashapp.com/stashLoader.js"></script>
    <script>
      loadStashExtract('123e4567-e89b-12d3-a456-426614174000', 'your_api_key_here')
    </script>
    `;

    return (
        <HStack alignItems={"start"} spacing={"24px"}>
            <Box borderWidth="1px" borderRadius="lg" p={4} width={"25%"}>
                <VStack alignItems={"start"} spacing={2}>
                    <Button onClick={onBack}>Back</Button>
                    <Heading as="h2" size="md">Configurations</Heading>
                    <Box>
                        <Text>Widget UI</Text>
                        <Text>Search Behavior</Text>
                        <Text>API Response</Text>
                        <Text>Embed Code</Text>
                    </Box>
                </VStack>
            </Box>
            <VStack spacing={4} align="stretch" width={"50%"}>
                <Heading as="h1" size="xl">Extract Fields</Heading>
                <Text>Add fields below for the retrieval agent to extract from the sources.</Text>
                {inputItems.map((item, index) => (
                    <HStack key={index}>
                        <Input
                            placeholder="Name"
                            value={item.name}
                            onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                        />
                        <Input
                            placeholder="Description"
                            value={item.description}
                            onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                        />
                        <IconButton
                            icon={<CloseIcon />}
                            colorScheme="red"
                            onClick={() => handleRemoveItem(index)}
                            aria-label="Remove field"
                        />
                    </HStack>
                ))}
                <Button onClick={handleAddItem}>Add Field</Button>
                <Button mt={2} colorScheme="blue" onClick={extractFromData} isLoading={isLLMLoading}>Run</Button>
                <Box mt={2} p={4} border="1px solid" borderColor="gray.200">
                    <Text>Output:</Text>
                    <Grid templateColumns="auto 1fr" gap={4}>
                        {llmOutput.map((output, index) => (
                            <React.Fragment key={index}>
                                <GridItem>
                                    <VStack alignItems={"start"}>
                                        <Text fontWeight="bold">{output.name}:</Text>
                                        <Text>({output.sourcePage})</Text>
                                    </VStack>
                                </GridItem>
                                <GridItem>
                                    <MarkdownRenderer content={output.value} />
                                </GridItem>
                            </React.Fragment>
                        ))}
                    </Grid>
                </Box>
                <Heading as="h2" size="md">Embed</Heading>
                <MarkdownRenderer content={embedCodeSample}/>
            </VStack>
        </HStack>
    );
}

export default Extract;