
import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Heading,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Button,
    SimpleGrid,
    Input,
    Text,
    FormControl,
    FormLabel
} from '@chakra-ui/react';

const WorkSpace = () => {
    const [circleColor, setCircleColor] = useState('green');
    const [image, setImage] = useState(null);
    const [inputText, setInputText] = useState('');

    const navigate = useNavigate();



    const colors = [
        "red", "blue", "green", "purple", "orange",
        "yellow", "cyan", "magenta", "pink", "lime",
        "teal", "brown"
    ];


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
                setCircleColor('');
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Box
            width="100%"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor="gray.100"
        >
            <Box width={{ base: '90%', md: '400px' }} textAlign="center">
                <Heading as="h1" size="lg" mb={4} textAlign="start">
                    Workspace Name
                </Heading>
                <Text textAlign="start">Fill in some details for your team.</Text><br />
                <Box
                    width="100px"
                    height="100px"
                    borderRadius="50%"
                    backgroundColor={image ? 'transparent' : circleColor}
                    backgroundImage={image ? `url(${image})` : 'none'}
                    backgroundSize="cover"
                    backgroundPosition="center"
                    border="2px solid white"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mx="auto"
                    mb={4}
                >

                    <Text fontSize="lg" color="white" fontWeight="bold">
                        {inputText.slice(0, 2).toUpperCase()}
                    </Text>
                </Box>


                <Popover>
                    <PopoverTrigger>
                        <Link color="blue.500" fontWeight="bold" cursor="pointer">
                            Choose Avatar
                        </Link>
                    </PopoverTrigger>
                    <PopoverContent width="220px">
                        <PopoverBody>

                            <SimpleGrid columns={7} spacing={2} mb={2}>
                                {colors.map((color) => (
                                    <Button
                                        key={color}
                                        size="xs"
                                        width="20px"
                                        height="20px"
                                        backgroundColor={color}
                                        onClick={() => {
                                            setCircleColor(color);
                                            setImage(null);
                                        }}
                                        _hover={{ opacity: 0.8 }}
                                        borderRadius="full"
                                    />
                                ))}
                            </SimpleGrid>


                            <label htmlFor="image-upload">
                                <Button
                                    as="span"
                                    mt={2}
                                    colorScheme="teal"
                                    variant="outline"
                                    width="100%"
                                >
                                    + Upload Image
                                </Button>
                            </label>
                            <Input
                                id="image-upload"
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                display="none"
                            />
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
                <br />
                <br />
                <br />


                <FormControl id="text" isRequired>
                    <FormLabel fontWeight="bold">Workspace Name</FormLabel>
                    <Input
                        placeholder="Enter text..."
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        mt={1}
                    />
                </FormControl>
                <Text textAlign='start' color='gray'>The name of your company or organization</Text>
                <Button
                    type="submit"
                    width="full"
                    mt={50}
                    colorScheme="blue"
                    bg={'gray.700'}
                    color={'white'}
                    _hover={'blue.600'}
                    onClick={() => navigate('/inviteteam')}
                >
                    Continue
                </Button>
            </Box>
        </Box>
    );
};

export default WorkSpace;
