import React, { useEffect, useState } from "react";
import {
    Box,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    Text,
    Select,
    Button,
    Link
} from '@chakra-ui/react';

const ProPlan = () => {
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        // Fetch country data from REST Countries API
        fetch("https://restcountries.com/v3.1/all")
            .then(response => response.json())
            .then(data => {
                // Extract and sort country names
                const countryNames = data
                    .map(country => country.name.common)
                    .sort();
                setCountries(countryNames);
            })
            .catch(error => console.error("Error fetching country data:", error));
    }, []);

    return (
        <Box
            width="100%"
            height="full"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box width={{ base: '90%', md: '400px' }}>
                <Heading as="h1" size="lg" textAlign="start" mb={1} mt={10} fontSize={33}>
                    Try the Pro Plan Free for 14 Days
                </Heading>
                <Text fontSize={13}>Use Stash as much as you want, with as many people as you like for two weeks, risk free. Cancel anytime.</Text><br />
                <FormControl isRequired>
                    <FormLabel fontSize={13} fontWeight='bold' mb={1}>Name</FormLabel>
                    <Input
                        type="text"
                        placeholder="Enter your Name"
                        mt={0}
                        
                    />
                </FormControl>
                <FormControl isRequired mt={1}>
                    <FormLabel fontSize={13} fontWeight='bold' mb={1}>Business Name</FormLabel>
                    <Input
                        type="text"
                        placeholder="Enter your Business Name"
                        mt={0}
                    />
                </FormControl>
                <FormControl isRequired mt={1}>
                    <FormLabel fontSize={13} fontWeight='bold' mb={1}>Card Number</FormLabel>
                    <Input
                        type="number"
                        placeholder="Enter your Card Number"
                        mt={0}
                    />
                </FormControl>
                <Stack direction='row'>
                    <FormControl isRequired mt={1}>
                        <FormLabel fontSize={13} fontWeight='bold' mb={1}>Expiration</FormLabel>
                        <Input
                            type="text"
                            placeholder="MM/YY"
                            mt={0}
                        />
                    </FormControl>
                    <FormControl isRequired mt={1}>
                        <FormLabel fontSize={13} fontWeight='bold' mb={1}>CVC</FormLabel>
                        <Input
                            type="text"
                            placeholder="CVC"
                            mt={0}
                        />
                    </FormControl>
                </Stack>
                <Stack direction='row'>
                    <FormControl isRequired mt={1}>
                        <FormLabel fontSize={13} fontWeight='bold' mb={1}>Country</FormLabel>
                        <Select placeholder="Select your Country" mt={0}>
                            {countries.map((country, index) => (
                                <option key={index} value={country}>
                                    {country}
                                </option>
                            ))}
                        </Select>
                    </FormControl> 
                    <FormControl isRequired mt={1}>
                        <FormLabel fontSize={13} fontWeight='bold' mb={1}>Zip</FormLabel>
                        <Input
                            type="text"
                            placeholder="Zip Code"
                            mt={0}
                        />
                    </FormControl>
                </Stack>
                <Text fontSize='12.5' mt='7'>
                    By providing your card information, you allow Stash to Charge your card for future payments in accordance with their terms.
                </Text>

                <Text fontSize='12.5' mt='4'>
                    At the end of your trail, every member of your team will be automatically upgrade to the full price of $12 / member / month.
                </Text>
                <Button
                    type="submit"
                    width='full'
                    mt='7'
                    bg='gray.700'
                    color='white'
                >
                    Start Pro Trial
                </Button>
                <Text textAlign='center' mt='10' mb='10' fontWeight='bold'>No thanks, <Link> continue to Free plan </Link></Text>
            </Box>
        </Box>
    );
};

export default ProPlan;
