
import React, { useState } from 'react';
import {
    Box,
    Button,
    Heading,
    Stack,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    Icon,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { useNavigate } from "react-router-dom";

const ProfilePlan = () => {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('');

    return (
        <Box
            width="100%"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box width={{ base: '90%', md: '400px' }}>
                <Heading as="h1" size="lg" textAlign="start" mb={4}>
                    How Do You Plan On Using Stash?
                </Heading>
                <FormControl as="fieldset" mb={4}>
                    <FormLabel as="legend" fontWeight="bold">
                        Choose one:
                    </FormLabel>
                    <RadioGroup onChange={setSelectedOption} value={selectedOption}>
                        <Stack spacing={4}>

                            <Box
                                border="1px solid"
                                borderColor={selectedOption === "team" ? "blue.500" : "gray.300"}
                                borderRadius="md"
                                p={3}
                                onClick={() => setSelectedOption("team")}
                                cursor="pointer"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Radio
                                    value="team"
                                    isChecked={selectedOption === "team"}
                                    onChange={() => setSelectedOption("team")}
                                    colorScheme="blue"
                                    icon={<Icon as={CheckIcon} />}
                                    sx={{
                                        "& > span": {
                                            bg: "transparent",
                                            borderColor: selectedOption === "team" ? "blue.500" : "gray.300",
                                        },
                                    }}
                                >
                                    With My Team
                                </Radio>
                            </Box>

                            
                            <Box
                                border="1px solid"
                                borderColor={selectedOption === "personal" ? "blue.500" : "gray.300"}
                                borderRadius="md"
                                p={3}
                                onClick={() => setSelectedOption("personal")}
                                cursor="pointer"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Radio
                                    value="personal"
                                    isChecked={selectedOption === "personal"}
                                    onChange={() => setSelectedOption("personal")}
                                    colorScheme="blue"
                                    icon={<Icon as={CheckIcon} />}
                                    sx={{
                                        "& > span": {
                                            bg: "transparent",
                                            borderColor: selectedOption === "personal" ? "blue.500" : "gray.300",
                                        },
                                    }}
                                >
                                    Personal Use
                                </Radio>
                            </Box>
                        </Stack>
                    </RadioGroup>
                </FormControl>
                <Button
                    type="submit"
                    width="full"
                    mt={4}
                    colorScheme="blue"
                    bg={selectedOption ? 'gray.600' : 'gray.400'}
                    color={selectedOption ? 'white' : 'gray.600'}
                    _hover={selectedOption ? { bg: 'blue.600' } : {}}
                    isDisabled={!selectedOption}
                    onClick={() => navigate('/stashwork')}
                >
                    Continue
                </Button>
            </Box>
        </Box>
    );
};

export default ProfilePlan;
