import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Button, Divider,
    Flex, HStack,
    List,
    ListItem,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Text,
    useDisclosure,
    VStack,
    Image,
    Collapse,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Input,
} from "@chakra-ui/react";
import { StashIconDark, StashIconLight } from "./StashIcon";
import { FaChartBar, FaHome, FaCodeBranch, FaFile, FaPlus, FaUser, FaChevronDown } from "react-icons/fa";
import { MdOutlineDashboard, MdCallSplit, MdRepeat } from "react-icons/md";
import CreateStashModal from "./CreateStashModal";
import { useAuth } from "../auth/AuthContext";
import collapse from '../images/Collapse.svg';
import ljpixel from '../images/ljpixel.svg';
import Down from '../images/Down.svg';
import Home from '../images/Home.svg';
import plus from '../images/Add.svg';
import more from '../images/More.svg';
import close from '../images/Close.svg';
import rename from '../images/rename.svg';
import './../cssfiles/sidebar.css';
import { Tooltip } from '@chakra-ui/react'
import overview from '../images/overview.svg';
import sources from '../images/sourceadd.svg';
import configurations from '../images/Configurations.svg';
import deploy from '../images/deploymentsadd.svg';



const Sidebar = ({ isExpanded, toggleSidebar }) => {
    // const [isExpanded, setIsExpanded] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();
    const { user, logout } = useAuth();
    const [stashes, setStashes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [stash, setStash] = useState(null);
    const [currentPage, setCurrentPage] = useState('dashboard');
    const [isPagesVisible, setIsPagesVisible] = useState(false);
    const [isRenameOpen, setIsRenameOpen] = useState(false);
    const [renameValue, setRenameValue] = useState('');
    const [selectedStash, setSelectedStash] = useState(null);
    const [expandedStashes, setExpandedStashes] = useState({});

    const pages = [
        {
            id: "homescreen",
            label: "Overview",
            image: overview,
            enabled: true
        },
        {
            id: "homescreen",
            label: "Sources",
            image: sources,
            enabled: true
        },
        // {
        //     id: "homescreen",
        //     label: "Data",
        //     image:,
        //     enabled: false
        // },
        {
            id: "homescreen",
            label: "Configurations",
            image: configurations,
            enabled: false
        },
        {
            id: "homescreen",
            label: "Deploy",
            image: deploy,
            enabled: true
        }

    ];

    const handleStashClick = (id) => {
        navigate(`/stashes/${id}`);
        setCurrentPage('dashboard')
    };

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const getInitials = (name) => {
        return name
            .split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase();
    };

    useEffect(() => {
        fetchStashes();

        const fetchStashDetail = async () => {
            try {
                const response = await fetch(`https://stash-fn-dev.azurewebsites.net/api/GetStashDetail?code=BHgAjybLftMBMir9LIfOuf3fKDjEhhmhxB0pS3ZBqYR5AzFuu3MKbw%3D%3D&id=${id}&userId=${user.userId}`, {
                    method: 'GET'
                });
                if (!response.ok) throw new Error('Failed to fetch stash details');
                const stash = await response.json();
                setStash(stash);
            } catch (error) {
                setError(error.message);
            }
        };

        if (user && user.userId) {
            if (id) {
                fetchStashDetail();
            }
        } else {
            navigate('/login');
        }
        // eslint-disable-next-line
    }, [id, user]);

    useEffect(() => {
        setStash(null)
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (!id && stashes && stashes.length > 0) {
            setCurrentPage('dashboard');
            //We can't get a stash detail, default to first stash if we have one.
            handleStashClick(stashes[0].id)
        }
        // eslint-disable-next-line
    }, [stashes]);

    const fetchStashes = async () => {
        if (user && user.userId) {
            setIsLoading(true);
            try {
                const response = await fetch(`https://stash-fn-dev.azurewebsites.net/api/GetStashes?code=XKWSM1OB3_xdke3yTkLNgWtmOFD5zr2RobUhWpqfRixsAzFuHJS3rg%3D%3D&userId=${user.userId}`, {
                    method: "GET"
                });
                if (!response.ok) throw new Error("Failed to fetch stashes");
                const data = await response.json();
                setStashes(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleOnCreate = (name, iconColor) => {
        onClose()
        addStash(name, iconColor)
    }

    const handleRenameClick = (stash) => {
        setRenameValue(stash.name);
        setSelectedStash(stash);
        setIsRenameOpen(true);
    };


    const handleRenameSave = async () => {
        if (!selectedStash) return;
        try {
            const response = await fetch("https://stash.azurewebsites.net/api/RenameStash?code=YOUR_API_CODE", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ stashId: selectedStash.id, newName: renameValue, userId: user.userId }),
            });
            if (!response.ok) throw new Error("Failed to rename stash");
            const updatedStash = await response.json();

            // Update the stash in the local state
            setStashes((prevStashes) =>
                prevStashes.map((stash) =>
                    stash.id === updatedStash.id ? { ...stash, name: updatedStash.name } : stash
                )
            );
            setIsRenameOpen(false);
        } catch (error) {
            setError(error.message);
        }
    };


    const handleDelete = async () => {
        try {
            const response = await fetch(`https://stash-fn-dev.azurewebsites.net/api/DeleteStash?code=DL5zC_h3PZWpJ_IBotjWQbxT673DMJ869oCmPJl8nhdfAzFusMhT-Q%3D%3D`, {
                method: 'DELETE',
                body: JSON.stringify({ stashId: id, userId: user.userId })
            });
            if (!response.ok) throw new Error('Failed to delete stash');
            setStash(null)
            navigate('/dashboard');
        } catch (error) {
            setError(error.message);
        }
    };

    const addStash = async (name, iconColor) => {
        try {
            const response = await fetch("https://stash-fn-dev.azurewebsites.net/api/AddStash?code=Wei9WWB6FwHIZWDWyV9Nl-3ZU9V_GQoC-J_Opnk24vc-AzFuF7F2dg%3D%3D", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ name, iconColor, userId: user.userId }),
            });
            if (!response.ok) throw new Error("Failed to create stash");
            const data = await response.json();
            handleStashClick(data.stash.rowKey)
        } catch (error) {
            setError(error.message);
        }
    };

    function isLightColor(hex) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        return luminance > 128;
    }

    const handleNavigation = (page) => {
        if (currentPage !== page) {
            setCurrentPage(page);
            if (page === 'dashboard') {
                if (stash) {
                    navigate(`/stashes/${stash.id}`)
                } else {
                    navigate("/dashboard")
                }
            } else {
                navigate(`/stashes/${stash.id}/${page}`);
            }
        }
    };

    const toggleStashExpansion = (stashId) => {
        setExpandedStashes((prevState) => ({
            ...prevState,
            [stashId]: !prevState[stashId], // Toggle the state for the specific stash
        }));
    };

    return (
        <HStack alignSelf="start" h="100vh" spacing={0}>
            <Box
                as="nav"
                position="fixed"
                w={isExpanded ? "20%" : "8%"}
                h="100vh"
                bg='gray.50'
                color="gray.800"
                borderRight="1px solid #E8EAED"
                transition="width 0.2s"
                display="flex"
                flexDirection="column"
                overflow="hidden"
            >

                <CreateStashModal isOpen={isOpen} onClose={onClose}
                    onCreate={(name, iconColor) => handleOnCreate(name, iconColor)} />
                <Flex h="60px" alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center" justifyContent='space-between' width='100%'>
                        <Box ml={isExpanded ? '10%' : "30%"} _hover={{ bg: "#F6F7F8" }} py="1" px='2' borderRadius="md">
                            {isExpanded ?
                                <Menu menuAlign="center">
                                    <MenuButton px='4px' py='4px'>
                                        <div style={{ display: "flex" }}>
                                            <Image src={ljpixel} alt="Logo" />
                                            <Text ml={2}>LJ Pixels</Text>
                                            <Image src={Down} alt="Logo" ml={2} />
                                        </div>
                                    </MenuButton>
                                    <MenuList style={{ paddingTop: "1px", paddingBottom: "1px" }} bg='#F6F7F8'>
                                        <MenuItem>
                                            <div><Image src={ljpixel} alt="Logo" /></div>
                                            <div>
                                                <Text ml={5}>LJ Pixels</Text>
                                                <Text ml={5}>Pro • {stashes.length} members</Text>
                                            </div>
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                                :
                                <Tooltip label="LJ Pixels" placement='right'>
                                    <Image src={ljpixel} alt="Logo" />
                                </Tooltip>}
                        </Box>
                        {isExpanded ? <Box
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            borderRadius='100%'
                            h={30}
                            width={30}
                            mr='24px'
                            _hover={{ bg: "gray.100" }}
                            className="btn shadow-sml menu-button"
                            onClick={toggleSidebar}
                        >
                            <Tooltip label='Collapse' placement='right'>
                                <Image src={collapse} alt="Google" boxSize="20px" />
                            </Tooltip>
                        </Box> : null}
                    </Flex>
                    <Divider orientation="horizontal" borderColor="#E8EAED" position="absolute" top="55px" w="100%" />
                </Flex>

                <Flex h="45px" alignItems="center" >
                    <Box py="2" px='2' borderRadius="md" ml={isExpanded ? '10%' : "30%"} display='flex' flexDirection='row' _hover={{ bg: "gray.100" }} onClick={() => navigate("/dashboard")}>
                        {isExpanded ?
                            <Image src={Home} alt="Logo" boxSize="20px" /> :
                            <Tooltip label='Home' placement='right'>
                                <Image src={Home} alt="Logo" boxSize="20px" />
                            </Tooltip>}
                        <Text ml={3} className="sidetext"> {isExpanded ? "Home" : ""}</Text>
                    </Box>
                </Flex>

                <Divider orientation="horizontal" borderColor="#E8EAED" position="relative" w="100%"></Divider>
                <Flex h="60px" alignItems="center" width='100%' >

                    <Box onClick={onOpen} ml={isExpanded ? '10%' : "30%"} _hover={{ bg: "gray.100" }} py="2" px='2' borderRadius="md" display='flex' flexDirection='row' width={isExpanded ? '100%' : '10'} justifyContent="space-between" >
                        <Text className="sidetext" fontWeight='500' fontFamily='inter' fontSize='14px' fontStyle='normal' lineHeight='20px'>
                            {isExpanded ? "Stashes" : null}</Text>
                        {isExpanded ?
                            <Image src={plus} alt="Logo" mr={isExpanded ? '24px' : 0} /> :
                            <Tooltip label='New Stash' placement='right'>
                                <Image src={plus} alt="Logo" mr='50%' />
                            </Tooltip>}
                    </Box>

                </Flex>
                <VStack align="stretch" p={isExpanded ? "5" : "2"} flex="1" style={{ paddingTop: "0" }}>

                    {stash && (
                        <Box overflowY='auto' maxHeight='calc(100vh - 200px)'>
                            {isLoading && "Loading..."}
                            {error && <Text color="red.500">{error}</Text>}
                            {stashes.map((stash) => (
                                <Box>
                                    <Box
                                        ml={isExpanded ? '8%' : "30%"}
                                        display='flex'
                                        flexDirection='row'
                                        py="2"
                                        borderRadius="md"
                                        cursor="pointer"
                                        _hover={{ bg: "gray.100" }}
                                    >
                                        <div style={{ display: "flex", width: "100%" }}>
                                            <div onClick={() => toggleStashExpansion(stash.id)} style={isExpanded ? { display: "flex", flexDirection: "row", width: "90.5%" } : { display: "flex", flexDirection: "row", width: "100%" }}>

                                                <Box height='20px' width='20px' borderRadius="full" bg={stash.iconColor} display='flex' alignItems='center' justifyContent='center'>
                                                    {isExpanded ?
                                                        <Text color='white' onClick={() => setIsPagesVisible(!isPagesVisible)} fontSize={10}> {stash.name?.charAt(0).toUpperCase()}</Text> :
                                                        <Tooltip label={stash.name} placement='right'>
                                                            <Text color='white' fontSize={10}> {stash.name?.charAt(0).toUpperCase()}</Text>
                                                        </Tooltip>}

                                                </Box>
                                                <Text ml={3}
                                                    isTruncated
                                                    whiteSpace="nowrap"
                                                    fontSize='14px'
                                                    fontStyle='normal'
                                                    fontWeight='400'
                                                    lineHeight='20px'
                                                    overflow="hidden"
                                                    textOverflow="ellipsis"> {isExpanded ? stash.name : ""}</Text>
                                            </div>

                                            <Box style={isExpanded ? { width: "9.5%" } : { width: "100%" }} mr='5px'>
                                                {isExpanded ?
                                                    <Menu>
                                                        <MenuButton>
                                                            <Image src={more} alt="Logo" />
                                                        </MenuButton>
                                                        <MenuList style={{ paddingTop: "1px", paddingBottom: "1px" }}>
                                                            <MenuItem
                                                                onClick={() => handleRenameClick(stash)}
                                                            // onClick={() => navigate('/profile')} 
                                                            >
                                                                <Image src={rename} alt="Logo" mr="12px" />
                                                                Rename
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={handleDelete}
                                                            >
                                                                <Image src={close} alt="Logo" mr="12px" />
                                                                Delete
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                    : null}
                                            </Box>
                                        </div>
                                    </Box>

                                    {/* Collapsible list */}
                                    <Collapse in={expandedStashes[stash.id] || false}>
                                        <List ml={isExpanded ? '15%' : "35%"}>
                                            {pages.map((page) => (
                                                <NavItem
                                                    key={page.id}
                                                    id={page.id}
                                                    label={isExpanded ? page.label : ""}
                                                    image={page.image}
                                                    enabled={page.enabled}
                                                    color={stash.iconColor}
                                                    isSelected={currentPage === page.id}
                                                    handleNavigation={handleNavigation}
                                                />
                                            ))}
                                        </List>
                                    </Collapse>
                                </Box>
                            ))}
                            <Modal isOpen={isRenameOpen} onClose={() => setIsRenameOpen(false)}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Rename Stash</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Text mb={2}>Enter a new name for the stash:</Text>
                                        <Input
                                            value={renameValue}
                                            onChange={(e) => setRenameValue(e.target.value)}
                                            placeholder="Enter new name"
                                        />
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button colorScheme="blue" mr={3} onClick={handleRenameSave}>
                                            Save
                                        </Button>
                                        <Button variant="ghost" onClick={() => setIsRenameOpen(false)}>Cancel</Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>

                        </Box>
                    )}
                </VStack>
                <Box alignItems= 'center'>
                    <Divider orientation="horizontal" borderColor="#E8EAED" position="relative" w="100%"></Divider>
                    <Box py='24px' px='24px' width='100%'>
                        {isExpanded ? <Image src="/stash-logo-horizontal.svg" alt="Logo" height= '20px' width='90px' /> :
                            <Image src="/stash-icon.svg" alt="Logo" boxSize='20px' />}
                    </Box>
                </Box>
            </Box>
            <Divider orientation="vertical" />
        </HStack>
    );
};

export default Sidebar

const NavItem = ({ id, label, image, enabled, color, isSelected, handleNavigation }) => {
    return (
        <ListItem>
            <Button
                w={"100%"}
                onClick={() => handleNavigation(id)}
                isDisabled={!enabled}
                justifyContent={"start"}
                p="2"
                bg={isSelected ? "gray.100" : "gray.50"}
                _hover={!isSelected && { bg: "#F6F7F8" }}
            >
                <Image src={image} alt="Logo" boxSize='20px' />
                <Text ml="3" fontWeight='400' fontSize='14px'>{label}</Text>
            </Button>
        </ListItem>
    )
}