import React, { useState } from 'react';
import { HStack } from '@chakra-ui/react';
import Sidebar from './Sidebar';

const Layout = ({ selectedKey, children }) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleSidebar = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <HStack align="start" h="100vh">
            <Sidebar selectedKey={selectedKey} isExpanded={isExpanded} toggleSidebar={toggleSidebar} />
            {/* Pass isExpanded and toggleSidebar as props to children */}
            {React.cloneElement(children, { isExpanded, toggleSidebar })}
        </HStack>
    );
};

export default Layout;




// const Layout = ({ selectedKey, children }) => {
//     return (
//         <HStack align="start" h="100vh">
//             <Sidebar selectedKey={selectedKey} />
//             {children}
//         </HStack>
//     );
// };

// export default Layout;