import { Box, Image, Text, Flex } from "@chakra-ui/react";
import Slack from '../../images/Slack.svg';
import MTeams from '../../images/Teams.svg';
import Discord from '../../images/Discord.svg';
import Gmail from '../../images/Gmail.svg';
import API from '../../images/API.svg';
import Snowflake from '../../images/Snow.svg';

const FeaturedIntegrations = () => { 
    const integrations = [
        { image: Slack, title: 'Slack', description: 'Total Calls' },
        { image: MTeams, title: 'Microsoft Teams', description: 'Detected Errors' },
        { image: Discord, title: 'Discord', description: 'Active Users' },
        { image: Gmail, title: 'Gmail', description: 'Total Calls' },
        { image: API, title: 'API', description: 'Detected Errors' },
        { image: Snowflake, title: 'Snowflake', description: 'Active Users' }
    ];

    return (
        <Flex flexWrap="wrap" justifyContent="space-between" gap='8px'>
            {integrations.map((integration, index) => (
                <Box key={index} className="recentactivity"  flex="1 0 calc(33.333% - 16px)" px='12px' py='16px'>
                    <Flex alignItems="center">
                        <Image src={integration.image} alt={integration.title} className="recentround" />
                        <Box className="box-column" >
                            <Text className="box_font1">{integration.title}</Text>
                            <Text className="box_font">{integration.description}</Text>
                        </Box>
                    </Flex>
                </Box>
            ))}
        </Flex>
    );
};

export default FeaturedIntegrations;
