import { Box, Button, Flex, Heading, HStack, Text, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Avatar, Divider, Image, Icon } from "@chakra-ui/react";
import React, { useEffect, useState } from 'react';
import { useAuth } from "../../auth/AuthContext";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { AddIcon } from "@chakra-ui/icons";
import '../../cssfiles/dashboard.css'
import deploymentsadd from '../../images/deploymentsadd.svg';
import sourceadd from '../../images/sourceadd.svg';
import Add from '../../images/Add.svg';
import FeaturedIntegrations from "./overviewconst";



const Overview = ({ isExpanded, toggleSidebar }) => {


    const navigate = useNavigate();
    const { id } = useParams();
    const { user } = useAuth();



    return (
        <Box>
            <Text fontSize='32px' fontStyle='normal' fontWeight='500' lineHeight='34px' color="#000">
                Welcome YELLOW
            </Text>
            <Text fontSize='14px' fontStyle='normal' fontWeight='400' lineHeight='20px' color="var(--Gray-600, #566062)" marginBottom='24px' marginTop='8px'>Get started and add your first source by clicking a cell below, or select the <Text as="b" color="var(--Gray-Black, #020C0E)">“Add Source”</Text> button at the top of the page. </Text>
            <FeaturedIntegrations />
            <Flex
                mt={2}
                width="100%"
                alignItems="center"
                justifyContent="space-between"
            >
                <Text fontSize='20px' fontStyle='normal' fontWeight='500' lineHeight='24px' color="#1D2B2D" >Sources</Text>
                <Box display="flex" alignItems="center" cursor="pointer" _hover={{ bg: "gray.100" }} px={2} py={2} borderRadius={5}>
                    <Text fontSize="14px" fontWeight="500" color="gray.700" cursor="pointer">
                        View All
                    </Text>
                </Box>
            </Flex>
            <Box className="recentactivity" mt={3} mb={3} px='12px' py='16px'>
                <Flex justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <Image src={sourceadd} className="recentround1" />
                        <Box className="box-column">
                            <Text className="box_font">No Sources Added</Text>
                            <Text className="box_font">Create a repository of your data</Text>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" cursor="pointer" _hover={{ bg: "gray.100" }} px={2} py={2} mr={2} borderRadius={5}>
                        <Text className="box_font1" >Add Source </Text>
                        <Image mr={3} ml={3} src={Add} alt="Google" boxSize="20px" />
                    </Box>
                </Flex>
            </Box>
            <Flex
                width="100%"
                alignItems="center"
                justifyContent="space-between"
             >
                <Text fontSize='20px' fontStyle='normal' fontWeight='500' lineHeight='24px' color="#1D2B2D" >Deployments</Text>
                <Box display="flex" alignItems="center" cursor="pointer" _hover={{ bg: "gray.100" }} px={2} py={2} borderRadius={5}>
                    <Text fontSize="14px" fontWeight="500" color="gray.700" cursor="pointer">
                        View All
                    </Text>
                </Box>
            </Flex>
            <Box className="recentactivity" mt={3} mb={3} px='12px' py='16px'>
                <Flex justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <Image src={deploymentsadd} className="recentround1" />
                        <Box className="box-column">
                            <Text className="box_font">No Deployments Added</Text>
                            <Text className="box_font">Create a repository of your data</Text>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" cursor="pointer" _hover={{ bg: "gray.100" }} px={2} py={2} mr={2} borderRadius={5}>
                        <Text className="box_font1" >Add Source </Text>
                        <Image mr={3} ml={3} src={Add} alt="Google" boxSize="20px" />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
};

export default Overview;
