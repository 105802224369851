import React from 'react';
import {Button, Heading, Text, VStack} from '@chakra-ui/react';

function SelectMode({ onSelectMode, isDevMode }) {
    return (
        <VStack mt={10} spacing={4} align="stretch">
            <Heading as="h1" size="xl" mb={4}>Deploy</Heading>
            <Text mb={4}>Select a method to deploy and embed into your platform</Text>
            { isDevMode && (
                <Button onClick={() => {onSelectMode("search")}} variant="outline" size="lg" width="100%" borderRadius="md">
                    Search
                </Button>
            )}
            <Button onClick={() => {onSelectMode("chat")}} variant="outline" size="lg" width="100%" borderRadius="md">
                Chat
            </Button>
            <Button onClick={() => {onSelectMode("extract")}} variant="outline" size="lg" width="100%" borderRadius="md">
                Extract
            </Button>
            <Button onClick={() => {onSelectMode("compare")}} variant="outline" size="lg" width="100%" borderRadius="md">
                Compare
            </Button>
        </VStack>
    );
}

export default SelectMode;