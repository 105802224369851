import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext(null);

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {    
     const [user, setUser] = useState();     
     const [isLoading, setIsLoading] = useState(true);     
     useEffect(() => {         
        fetch('/.auth/me')
        .then(res => res.json())
        .then(data => {
            if (data.clientPrincipal) {
                const newUser = ({...data.clientPrincipal,
                                 userId: data.clientPrincipal.userId, 
                                 sendUserInfo: data.clientPrincipal.userDetails
                                });
            setUser(newUser);
            sendUserInfoToAPI(newUser);
        } else {
            setUser(null);
        }
        setIsLoading(false);
        });     
    }, []);
    const sendUserInfoToAPI = async (user) => {
        try {
            const response = await fetch('https://stash-fn-dev.azurewebsites.net/api/UserRegister?code=gAVeuhB5n29gKHEJRnntQeNGKXSo2VvF17G3O89M-HANAzFu8jDl2g%3D%3D', {
                method: 'POST',  headers: { 
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({                     
                    userId: user.userId,
                    emailAddress: user.userDetails                    
                    // Add any other user information you want to send
                }) 
            });
            if (response.ok) {
                const result = await response.json();             
                console.log('User info sent successfully:'  , result);  
            }                   
        } catch (error) {
            console.error('Error sending user info to API:', error);
        }
    };     
    return (
        <AuthContext.Provider value={{ user, isLoading }}> 
        {children}</AuthContext.Provider>
    ); 
}