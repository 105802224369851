import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Link,
  List,
  ListItem,
  ListIcon,
  Alert,
  AlertIcon,
  Image,
  InputGroup,
  InputRightElement
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import googleLogo from "../../images/Google.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";
import { CloseIcon } from "@chakra-ui/icons";

function PasswordSetup() {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { user } = useAuth();
  const navigate = useNavigate();
  const handleClearName = () => setName("");
  const handleClearPassword = () => setPassword("");

  const validatePassword = () => {
    setError("");
    const requirements = [
      password.length >= 8,
      /[!@#$%^&*(),.?":{}|<>]/.test(password),
      /[A-Z]/.test(password),
    ];

    const isValid = requirements.every(Boolean);

    if (!isValid) {
      const errorMessages = [];
      if (requirements[0] === false) {
        errorMessages.push("Must be 8 characters");
      }
      if (requirements[1] === false) {
        errorMessages.push("Must have 1 special character");
      }
      if (requirements[2] === false) {
        errorMessages.push("Must have 1 capital letter");
      }
      setError(errorMessages.join(", "));
    } else {
      alert("Password is valid!");
    }
  };
  useEffect(() => {
    if (user) {
      navigate("/dashboard", { replace: true });
    }
  }, [user, navigate]);

  return (
    <Center minHeight="100vh" p={4}>
      <Box maxW="400px" w="full" textAlign="center">
        <Heading as="h1" size="lg" textAlign="start" mb={1}>
          Welcome to Stash
        </Heading>
        <Text textAlign="start">Tell us a little bit about yourself</Text>
        <br />
        <Stack spacing={2}>
          <FormControl id="name">
            <FormLabel fontWeight="bold">Name</FormLabel>
            <InputGroup>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {name && (
                <InputRightElement>
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={handleClearName}
                    variant="unstyled"
                  >
                    <CloseIcon w={3} h={3} />
                  </Button>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>

          <FormControl id="password" isInvalid={!!error}>
            <FormLabel fontWeight="bold">Set a Password</FormLabel>
            <InputGroup>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {password && (
                <InputRightElement>
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={handleClearPassword}
                    variant="unstyled"
                  >
                    <CloseIcon w={3} h={3} />
                  </Button>
                </InputRightElement>
              )}
            </InputGroup>
            {error && (
              <Alert status="error" mt={2}>
                <AlertIcon />
                {error}
              </Alert>
            )}
          </FormControl>

          <Box textAlign="Start">
            <List>
              <ListItem fontWeight="bold" fontSize="xs">
                • Must be 8 characters
              </ListItem>
              <ListItem fontWeight="bold" fontSize="xs">
                • Must have 1 special character
              </ListItem>
              <ListItem fontWeight="bold" fontSize="xs">
                • Must have 1 capital letter
              </ListItem>
            </List>
          </Box>

          <Button
            type="submit"
            width="full"
            mt={4}
            colorScheme="gray.700"
            bg={"gray.700"}
            color={"white"}
            onClick={() => {
              validatePassword();
              navigate('/stashplan');
            }}
          >
            Continue
          </Button>


          <Stack direction="row" alignItems="center">
            <Divider />
            <Text fontSize="sm" color="gray.500" px={2}>
              or
            </Text>
            <Divider />
          </Stack>

          <a href="/.auth/login/google">
            <Button width="full" variant="outline" mt={2}>
              <Image src={googleLogo} alt="Google" boxSize="20px" mr={2} />
              Continue with Google
            </Button>
          </a>

          <Text textAlign="center" fontSize="xs" mt={4}>
            By clicking "Continue with Google or email" above, you acknowledge
            that you have read and understood, and agree to Stash's{" "}
            <Link textDecoration="underline">Terms & Condition</Link> and{" "}
            <Link textDecoration="underline">Privacy Policy</Link>.
          </Text>
        </Stack>
      </Box>
    </Center>
  );
}

export default PasswordSetup;
