import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, ChakraProvider, Divider, Flex } from '@chakra-ui/react';
import theme from './theme/theme';
import { AnimatePresence } from 'framer-motion';
import { AuthProvider } from './auth/AuthContext';
import Login from './page/login/Login';
import Plan from './page/profile/plan';
import Work from './page/profile/workspace';
import InviteTeam from './page/profile/teamInvite';
import ProPlan from './page/profile/proplan';
import Header from './component/Header';
import Dashboard from './page/dashboard/Dashboard';
import Sources from './page/sources/Sources';
import Deploy from './page/deploy/Deploy';
import ProtectedRoute from './component/ProtectedRoute';
import Layout from './component/Layout';
import './App.css';
import Signup from './page/signup/signup';
import PasswordSetup from './page/signup/passwordsetup';
import Homescreen from './page/homescreen/Homescreen'
import Overview from './page/overview/Overview'

function App() {
    return (
        <AuthProvider>
            <Router>
                <ChakraProvider theme={theme}>
                    <AnimatePresence>
                        <Box className="App"> 
                            {/* <Header /> */}
                           
                            <Divider />
                            <Flex direction="column" h="100vh">
                                <Flex flex="1">
                                    <Divider orientation="vertical" />
                                    <Box flex="1" alignItems={'start'}>
                                        <Routes>
                                            <Route path="/" element={<Login />} />
                                            <Route path="/login" element={<Login />} />
                                            <Route path="/signup" element={<Signup/>} />
                                            <Route path="/passwordsetup" element={<PasswordSetup/>} />
                                            <Route path="/stashplan" element={<Plan/>} />
                                            <Route path="/stashwork" element={<Work/>} />
                                            <Route path="/inviteteam" element={<InviteTeam/>} />
                                            <Route path="/proplan" element={<ProPlan/>} />
                                            {/* Protected Routes */}
                                            
                                            

                                            <Route
                                                path="/dashboard"
                                                element={
                                                    <ProtectedRoute>
                                                        <Layout selectedKey="dashboard">
                                                            <Dashboard />
                                                        </Layout>
                                                    </ProtectedRoute>
                                                }
                                            />
                                            <Route
                                                path="/stashes/:id"
                                                element={
                                                    <ProtectedRoute>
                                                        <Layout selectedKey="dashboard">
                                                            <Dashboard />
                                                        </Layout>
                                                    </ProtectedRoute>
                                                }
                                            />
                                            <Route
                                                path="/stashes/:id/sources"
                                                element={
                                                    <ProtectedRoute>
                                                        <Layout selectedKey="sources">
                                                            <Sources />
                                                        </Layout>
                                                    </ProtectedRoute>
                                                }
                                            /> 
                                            <Route
                                                path="/stashes/:id/deploy"
                                                element={
                                                    <ProtectedRoute>
                                                        <Layout selectedKey="deploy">
                                                            <Deploy />
                                                        </Layout>
                                                    </ProtectedRoute>
                                                }
                                            />
                                            <Route
                                                path="/stashes/:id/homescreen"
                                                element={
                                                    <ProtectedRoute>
                                                        <Layout selectedKey="homescreen">
                                                            <Homescreen />
                                                        </Layout>
                                                    </ProtectedRoute>
                                                }
                                            />
                                            {/* Dev Mode */}
                                            <Route
                                                path="/stashes/:id/deploy-dev"
                                                element={
                                                    <ProtectedRoute>
                                                        <Layout selectedKey="deploy">
                                                            <Deploy isDevMode={true} />
                                                        </Layout>
                                                    </ProtectedRoute>
                                                }
                                            />
                                        </Routes>
                                    </Box>
                                </Flex>
                            </Flex>
                        </Box>
                    </AnimatePresence>
                </ChakraProvider>
            </Router>
        </AuthProvider>
    );
}

export default App;