import { Box, Button, Flex, Heading, HStack, Text, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Avatar, Divider, Image, Icon, Tooltip, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { useAuth } from "../../auth/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { MdOutlineDashboard, MdCallSplit, MdRepeat } from "react-icons/md";
import { FaCodeBranch, FaFile } from "react-icons/fa";
import "../../cssfiles/dashboard.css";
import expand from "../../images/Expand.svg";
import morevertical from "../../images/Morev.svg";
import plus from "../../images/Addw.svg";
import OverView from "./../overview/Overview";
import SourcesPage from "./../sources/Sources";
import DataPage from "./../sources/Sources";
import ConfigurationsPage from "./../sources/Sources";
import DeployPage from "./../deploy/Deploy";
import up from '../../images/Up.svg';
import down from '../../images/Down.svg';
import logout from '../../images/Logout.svg';
import notification from '../../images/Notifications.svg';

const Homescreen = ({ isExpanded, toggleSidebar }) => {
    const [activePage, setActivePage] = useState("dashboard");
    const menuDisclosure = useDisclosure();

    const pages = [
        {
            id: "dashboard",
            label: "Overview",
            icon: <MdOutlineDashboard />,
            component: <OverView />,
        },
        {
            id: "sources",
            label: "Sources",
            icon: <FaCodeBranch />,
            component: <SourcesPage />,
        },
        {
            id: "data",
            label: "Data",
            icon: <FaFile />,
            component: <DataPage />,
        },
        {
            id: "configurations",
            label: "Configurations",
            icon: <MdRepeat />,
            component: <ConfigurationsPage />,
        },
        {
            id: "deploy",
            label: "Deploy",
            icon: <MdCallSplit />,
            component: <DeployPage />,
        },
    ];

    const navigate = useNavigate();
    const { user } = useAuth();

    const getInitials = (name) => {
        return name
            .split(" ")
            .map((word) => word[0])
            .join("")
            .toUpperCase();
    };

    const handleLogout = async () => {
        try {
            // await logout();
            navigate("/login");
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };

    const CustomAvatar = ({ name, size = "24px", fontSize = "12px" }) => {
        const initials = getInitials(name || "User");
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                bg="#020C0E"
                color="white"
                borderRadius="50%"
                height={size}
                width={size}
            >
                <Box fontSize={fontSize} >
                    {initials}
                </Box>
            </Box>
        );
    };

    return (
        <Flex direction="column" height="100%" width={isExpanded ? "80%" : "92%"} ml={isExpanded ? "20%" : "8%"}>
            <Divider orientation="horizontal" borderColor="#E8EAED" position="absolute" top="55.8px" width={isExpanded ? "79.9%" : "91.9%"} />
            <Flex justifyContent="space-between" alignItems="center" px={4} py={2} height="55px" width="100%">
                <Box mt={0} onClick={toggleSidebar} cursor="pointer" h={0} w={10}>
                    {isExpanded ? null : (
                        <Tooltip label="Expand" placement="right">
                            <Image src={expand} alt="Expand" boxSize="20px" />
                        </Tooltip>
                    )}
                </Box>
                <Box display='flex' flexDirection='row' alignItems='center' >
                    <Tooltip label='Notifications' placement="right">
                    <Image src={notification} alt="Collapse" mr='16px' />
                    </Tooltip>
                    <Menu isOpen={menuDisclosure.isOpen} onOpen={menuDisclosure.onOpen} onClose={menuDisclosure.onClose}>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}>
                            <Box
                                borderRadius="100"
                                bg='#F6F7F8'
                                p={2}
                            >
                                <HStack spacing={3}>
                                    <CustomAvatar name={user?.name} size="24px" fontSize="12px" />
                                    {menuDisclosure.isOpen ? (
                                        <Image src={up} alt="Collapse" />
                                    ) : (
                                        <Image src={down} alt="Expand" />
                                    )}
                                </HStack>
                            </Box>
                        </MenuButton>
                        <MenuList boxShadow="lg" height='104px' w='261px' >
                            <MenuItem icon={<FaUser />} onClick={() => navigate('/profile')} borderRadius="md" px='16px' py='12px'>
                                Profile
                            </MenuItem>
                            
                            <MenuItem onClick={handleLogout} borderRadius="md" px='16px' py='12px' >
                                <Image src={logout} alt="Collapse" mr='12px'/>
                                Logout
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" px={4} py={2} height="55px" width="100%">
                <Box display="flex">
                    {pages.map((page) => (
                        <Box
                            key={page.id}
                            mt="15px"
                            mx={4}
                            cursor="pointer"
                            onClick={() => setActivePage(page.id)}
                            opacity={1}
                        >
                            <Flex align="center">
                                <Icon as={page.icon.type} mr={2} color={activePage === page.id ? "#394547" : "#727A7C"} />
                                <Text
                                    fontWeight="400"
                                    fontSize="14px"
                                    fontStyle="normal"
                                    fontFamily="inter"
                                    lineHeight="20px"
                                    color={activePage === page.id ? "#394547" : "#727A7C"}
                                >
                                    {page.label}
                                </Text>
                            </Flex>
                            {activePage === page.id && <Box mt="10.7px" height="1.5px" bg="#000" borderRadius="full" />}
                        </Box>
                    ))}
                </Box>

                <Box display="flex" gap="8px" alignItems="center" mb="1">
                    <Image src={morevertical} alt="Menu"  />
                    <Button
                        height="38px"
                        variant="outline"
                        borderColor="gray.400"
                        color="black"
                        _hover={{ borderColor: "gray.600" }}
                        size="sm"
                        borderRadius="10"
                    >
                        <Text
                            padding="8px 24px"
                            fontWeight="400"
                            fontSize="14px"
                            fontStyle="normal"
                            fontFamily="inter"
                            lineHeight="20px"
                        >
                            Share
                        </Text>
                    </Button>
                    <Button
                        height="38px"
                        variant="outline"
                        borderColor="gray.400"
                        color="black"
                        _hover={{ borderColor: "gray.600" }}
                        size="sm"
                        borderRadius="10"
                        bg="#394547"
                    >
                        <Image src={plus} alt="Add Source" mr={1} filter="brightness(0) invert(1)" />
                        <Text
                            fontWeight="400"
                            fontSize="14px"
                            fontStyle="normal"
                            fontFamily="inter"
                            lineHeight="20px"
                            color="white"
                        >
                            Add Source
                        </Text>
                    </Button>
                </Box>
            </Flex>

            <Divider orientation="horizontal" position="absolute" top="106px" width={isExpanded ? "79.9%" : "91.9%"} />

            {/* Render the active page content */}
            <Flex width={isExpanded ? "70%" : "74%"} position='absolute' mt={150} ml={isExpanded ? 50 : 150} direction='column'>
                {pages.find((page) => page.id === activePage)?.component}
            </Flex>
        </Flex>
    );
};

export default Homescreen;
