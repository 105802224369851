// src/LoginPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext'
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Heading,
    Stack,
    Divider,
    Text,
    Link,
    InputGroup,
    InputRightElement,
    useToast,
    Image
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import googleLogo from '../../images/Google.svg';


const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const toast = useToast();
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate('/dashboard', { replace: true });
        }
        if (email.trim() || password.trim()) {
            setIsButtonEnabled(true);
        } else {
            setIsButtonEnabled(false);
        }

        if (password.trim()) {
            setShowForgotPassword(true);
        } else {
            setShowForgotPassword(false);
        }
    }, [email, password, user, navigate]);

    const handlePasswordVisibility = () => setShowPassword(!showPassword);

    const handleClearEmail = () => setEmail('');
    const handleClearPassword = () => setPassword('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!email || !password) {
            toast({
                title: "Missing Fields",
                description: "Please fill in all fields.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
    };

    return (
        <Box
            width="100%"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box width='490px'>
                <Heading className='heading' textAlign="start" >
                    Welcome to Stash
                </Heading>
                <Text mt='16px' mb='24px' className='subheading'>Enter your email to sign in</Text>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <FormControl id="email" isRequired>
                            <FormLabel className='formheading' mb='16px'>Email</FormLabel>
                            <InputGroup>
                                <Input
                                    borderRadius='8px'
                                    borderColor='#D0D6D7'
                                    _hover={{ borderColor: '#566062' }}
                                    _focus={{ borderColor: '#020C0E', boxShadow: "0 0 0 1px #020C0E", }}
                                    type="email"
                                    // placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {email && (
                                    <InputRightElement>
                                        <Button
                                            h="1.75rem"
                                            size="sm"
                                            onClick={handleClearEmail}
                                            variant="unstyled"
                                        >
                                            <CloseIcon w={3} h={3} />
                                        </Button>
                                    </InputRightElement>
                                )}
                            </InputGroup>
                        </FormControl>

                        <FormControl isRequired>
                            <FormLabel className='formheading' mb='16px' mt='16px'>Password</FormLabel>
                            <InputGroup>
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    //placeholder="Enter your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    borderRadius='8px'
                                    borderColor='#D0D6D7'
                                    _hover={{ borderColor: '#566062' }}
                                    _focus={{ borderColor: '#020C0E', boxShadow: "0 0 0 1px #020C0E", }}
                                />
                                {password && (
                                    <InputRightElement>
                                        <Button
                                            h="1.75rem"
                                            size="sm"
                                            onClick={handleClearPassword}
                                            variant="unstyled"

                                        >
                                            <CloseIcon w={3} h={3} />
                                        </Button>
                                    </InputRightElement>
                                )}
                            </InputGroup>
                            {showForgotPassword && (
                                <Text mt='24px' className='formheading'>Forgot your Password?</Text>
                            )}
                        </FormControl>

                        <Button
                            type="submit"
                            width="full"
                            mt='24px'
                            mb='24px'
                            colorScheme="blue"
                            borderColor={isButtonEnabled ? '#020C0E' : '#E8EAED'}
                            bg={isButtonEnabled ? '#394547' : '#E8EAED'}
                            color={isButtonEnabled ? 'white' : '#9BA1A2'}
                            _hover={isButtonEnabled ? { bg: '#566062' } : {}}
                            isDisabled={!isButtonEnabled}
                            onClick={() => navigate('/signup')}
                            className='formtext'
                        >
                            {isButtonEnabled ? 'Sign In' : 'Continue with Email'}
                        </Button>

                        <Stack direction="row" alignItems="center">
                            <Divider color='#E8EAED' />
                            <Text className='formtext' color='#A3A0B4' px='8px'>or</Text>
                            <Divider color='#E8EAED' />
                        </Stack>
                        <a href="/.auth/login/google">
                            <Button
                                width="full"
                                variant="outline"
                                mt='24px'
                                mb='24px'
                                className='formtext'
                                color='#394547'
                                borderRadius='8px'
                                borderColor='#D0D6D7'
                                _hover={{ bg: '#566062', borderColor: '#566062' }}
                            >
                                <Image src={googleLogo} alt="Google" boxSize="20px" mr='8px' />
                                Continue with Google
                            </Button></a>
                    </Stack>
                </form>

                <Text textAlign="center" className='formtext' color='#395A5F' >
                    Don't have an account?{' '}
                    <Link as={RouterLink} to="/signup" textDecoration='underline' textUnderlineOffset='2.5px'>
                        Sign Up
                    </Link>
                </Text>
            </Box>
        </Box>
    );
};

export default Login;
