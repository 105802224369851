import { Box, Image, Text, Flex } from "@chakra-ui/react";
import Addsource from '../../images/addsource.svg';
import Manageconfig from '../../images/manageconfig.svg';
import Deploystash from '../../images/deploystash.svg';



const FeaturedIntegrations = () => { 
    const integrations = [
        { image: Addsource, title: 'Add a Source', description: 'Create a repository of your data' },
        { image: Manageconfig, title: 'Manage Your Configurations', description: 'Create a repository of your data' },
        { image: Deploystash, title: 'Deploy a Stash', description: 'Create a repository of your data' },
    ];

    return (
        <Flex flexWrap="wrap" justifyContent="space-between" gap={4}>
            {integrations.map((integration, index) => (
                <Box key={index} className="recentactivity" maxW="calc(33.333% - 16px)"  px='12px' py='16px'>
                    <Flex alignItems="center">
                        <Image src={integration.image} alt={integration.title} className="recentround1" />
                        <Box className="box-column" >
                            <Text className="box_font1">{integration.title}</Text>
                            <Text className="box_font">{integration.description}</Text>
                        </Box>
                    </Flex>
                </Box>
            ))}
        </Flex>
    );
};

export default FeaturedIntegrations;
