import { Box, Button, Flex, Heading, HStack, Text, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Avatar, Divider, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from 'react';
import { useAuth } from "../../auth/AuthContext";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { AddIcon } from "@chakra-ui/icons";
import '../../cssfiles/dashboard.css'
import expand from '../../images/Expand.svg';
import Right from '../../images/Right.svg';
import plus from '../../images/Add.svg';
import up from '../../images/Up.svg';
import down from '../../images/Down.svg';
import logout from '../../images/Logout.svg';

import notification from '../../images/Notifications.svg';
import { Tooltip, useDisclosure } from '@chakra-ui/react'
import CreateStashModal from "../../component/CreateStashModal";
import FeaturedIntegrations from "./dashboardconst";
const Dashboard = ({ isExpanded, toggleSidebar }) => {

    const navigate = useNavigate();
    const { id } = useParams();
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [stash, setStash] = useState(null);
    const [error, setError] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [stashes, setStashes] = useState([]);
    const menuDisclosure = useDisclosure();

    const getInitials = (name) => {
        return name
            .split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase();
    };

    const handleLogout = async () => {
        try {
            // await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const handleOnCreate = (name, iconColor) => {
        onClose()
        addStash(name, iconColor)
    }

    const addStash = async (name, iconColor) => {
        try {
            const response = await fetch("https://stash-fn-dev.azurewebsites.net/api/AddStash?code=Wei9WWB6FwHIZWDWyV9Nl-3ZU9V_GQoC-J_Opnk24vc-AzFuF7F2dg%3D%3D", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ name, iconColor, userId: user.userId }),
            });
            if (!response.ok) throw new Error("Failed to create stash");
            const data = await response.json();
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchStashes();
        const fetchStashDetail = async () => {
            try {
                const response = await fetch(`https://stash-fn-dev.azurewebsites.net/api/GetStashDetail?code=BHgAjybLftMBMir9LIfOuf3fKDjEhhmhxB0pS3ZBqYR5AzFuu3MKbw%3D%3D&id=${id}&userId=${user.userId}`, {
                    method: 'GET'
                });
                if (!response.ok) throw new Error('Failed to fetch stash details');
                const stash = await response.json();
                setStash(stash);
            } catch (error) {
                setError(error.message);
            }
        };

        if (user && user.userId) {
            if (id) {
                fetchStashDetail();
            }
        } else {
            navigate('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, user, navigate]);

    const fetchStashes = async () => {
        if (user && user.userId) {
            setIsLoading(true);
            try {
                const response = await fetch(`https://stash-fn-dev.azurewebsites.net/api/GetStashes?code=XKWSM1OB3_xdke3yTkLNgWtmOFD5zr2RobUhWpqfRixsAzFuHJS3rg%3D%3D&userId=${user.userId}`, {
                    method: "GET"
                });
                if (!response.ok) throw new Error("Failed to fetch stashes");
                const data = await response.json();
                setStashes(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };
    const CustomAvatar = ({ name, size = "24px", fontSize = "12px" }) => {
        const initials = getInitials(name || "User");

        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                bg="#020C0E"
                color="white"
                borderRadius="50%"
                height={size}
                width={size}
            >
                <Box fontSize={fontSize} >
                    {initials}
                </Box>
            </Box>
        );
    };

    return (
        <Flex direction="column" height="100%" width={isExpanded ? "80%" : "92%"} ml={isExpanded ? "20%" : "8%"} >
            {/* <Divider orientation="horizontal" borderColor="#E8EAED" position="absolute" top="55px" w="10%" /> */}
            <Flex justifyContent="space-between" alignItems="center" px={4} py={2} height="55px" width="100%" >
                <Box mt={0} onClick={toggleSidebar} cursor='pointer' h={0} w={10}>
                    {isExpanded ? null :
                        <Tooltip label='Expand' placement='bottom'>
                            <Image src={expand} alt="Google" boxSize="20px" />
                        </Tooltip>
                        }
                </Box>
                <Box display='flex' flexDirection='row' alignItems='center' >
                    <Tooltip label='Notifications' placement="right">
                    <Image src={notification} alt="Collapse" mr='16px' />
                    </Tooltip>
                    <Menu isOpen={menuDisclosure.isOpen} onOpen={menuDisclosure.onOpen} onClose={menuDisclosure.onClose}>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}>
                            <Box
                                borderRadius="100"
                                bg='#F6F7F8'
                                p={2}
                            >
                                <HStack spacing={3}>
                                    <CustomAvatar name={user?.name} size="24px" fontSize="12px" />
                                    {menuDisclosure.isOpen ? (
                                        <Image src={up} alt="Collapse" />
                                    ) : (
                                        <Image src={down} alt="Expand" />
                                    )}
                                </HStack>
                            </Box>
                        </MenuButton>
                        <MenuList boxShadow="lg" height='104px' w='261px' >
                            <MenuItem icon={<FaUser />} onClick={() => navigate('/profile')} borderRadius="md" px='16px' py='12px'>
                                Profile
                            </MenuItem>
                            
                            <MenuItem onClick={handleLogout} borderRadius="md" px='16px' py='12px' >
                                <Image src={logout} alt="Collapse" mr='12px'/>
                                Logout
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
            </Flex>
            <Box >
                <Box height="180px" width="100%" bg="rgba(246, 247, 248, 0.50)" borderBottom="1px solid #E8EAED" borderTop='1px solid #E8EAED' />

            </Box>
            <Flex width={isExpanded ? "73%" : "74%"} position='absolute' mt='95px' ml={isExpanded ? 50 : 150} direction='column'>
                <Text fontSize='32px' fontStyle='normal' fontWeight='500' lineHeight='34px' color="#000">
                    Welcome, {user.userDetails}
                </Text>
                <Text fontSize='14px' fontStyle='normal' fontWeight='400' lineHeight='20px' color="var(--Gray-600, #566062)" marginBottom='24px' marginTop='8px'>Get started and add your first source by clicking a cell below, or select the <Text as="b" color="var(--Gray-Black, #020C0E)">“Add Source”</Text> button at the top of the page. </Text>
                <Text fontSize='20px' fontStyle='normal' fontWeight='500' lineHeight='24px' color="#1D2B2D" mb={3}>Recent Stashes</Text>
                <Flex justifyContent='flex-start' alignItems='start' flexWrap='wrap' gap={2.51} >
                    <CreateStashModal isOpen={isOpen} onClose={onClose}
                        onCreate={(name, iconColor) => handleOnCreate(name, iconColor)} />
                    <Box className="stashbox" onClick={onOpen} cursor="pointer">
                        <Image src={plus} alt="Logo" boxSize='24px' mt={3} ml={3} />
                        <Flex position="absolute" bottom={2} left={4} alignItems="center">
                            <Text fontSize='16px' fontStyle='normal' fontWeight='500' lineHeight='22px' color="var(--Gray-Black, #020C0E)">New Stash</Text>
                        </Flex>
                    </Box>
                    {stashes.map((stash) => (
                        <Box className="stashbox" paddingRight="16px" cursor="pointer">
                            <Box className="stashround" bg={stash.iconColor} mt={3} ml={3}>
                                <Text fontSize={10}>{stash.name?.charAt(0).toUpperCase()}</Text>
                            </Box>
                            <Flex position="absolute" bottom={2} left={4} alignItems="center">
                                <Text fontSize='16px' fontStyle='normal' fontWeight='500' lineHeight='22px' color="var(--Gray-Black, #020C0E)">{stash.name}</Text>
                            </Flex>
                        </Box>
                    ))
                    }
                </Flex>
                <Flex
                    mt='5'
                    mb='3'
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Text fontSize='20px' fontStyle='normal' fontWeight='500' lineHeight='24px' color="#1D2B2D" mb={3}>Recent Activity</Text>
                    <Box display="flex" alignItems="center" cursor="pointer" _hover={{ bg: "gray.100" }} px={2} py={2} borderRadius={5}>
                        <Text fontSize="14px" fontWeight="500" color="gray.700" cursor="pointer">
                            View All
                        </Text>
                    </Box>
                </Flex>

                <Box className="recentactivity" >
                    <Flex justifyContent="space-between" alignItems="center" >
                        <Box display="flex" alignItems="center" px='12px' py='16px'>
                            <Box className="recentround" bg='#64C6A2'>
                                <Text color='white' fontSize={10}>R</Text>
                            </Box>
                            <Box className="box-column">
                                <Text className="box_font1">Source Added</Text>
                                <Text className="box_font">Ne source added to "<Text as="row" color="var(--Gray-Black, #020C0E)" textDecoration="underline" cursor="pointer">Investment Regulations</Text>" </Text>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" cursor="pointer" _hover={{ bg: "gray.100" }} mr='16px' borderRadius={5}>
                            <Text className="box_font2" >View </Text>
                            <Image ml={3} src={Right} alt="Google" boxSize="20px" />
                        </Box>
                    </Flex>
                </Box>

                <Box className="recentactivity" mt='8px' mb='8px'>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center" px='12px' py='16px'>
                            <Box className="recentround" bg='#64C6A2'>
                                <Text color='white' fontSize={10}>R</Text>
                            </Box>
                            <Box className="box-column">
                                <Text className="box_font1">Source Added</Text>
                                <Text className="box_font">New source added to "<Text as="row" color="var(--Gray-Black, #020C0E)" textDecoration="underline" cursor="pointer">Knowledge Valut</Text>" </Text>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" cursor="pointer" _hover={{ bg: "gray.100" }} mr='16px' borderRadius={5}>
                            <Text className="box_font2" >View </Text>
                            <Image ml={3} src={Right} alt="Google" boxSize="20px" />
                        </Box>
                    </Flex>
                </Box>

                <Box className="recentactivity"  >
                    <Flex justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center" px='12px' py='16px'>
                            <Box className="recentround" bg='#64C6A2'>
                                <Text color='white' fontSize={10}>R</Text>
                            </Box>
                            <Box className="box-column">
                                <Text className="box_font1">Source Added</Text>
                                <Text className="box_font"><Text as="row" color="var(--Gray-Black, #020C0E)" textDecoration="underline" cursor="pointer">Knowledge Valut</Text> </Text>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" cursor="pointer" _hover={{ bg: "gray.100" }} mr='16px' borderRadius={5}>
                            <Text className="box_font2" >View </Text>
                            <Image ml={3} src={Right} alt="Google" boxSize="20px" />
                        </Box>
                    </Flex>
                </Box>
                <Flex
                    mt='5'
                    mb='3'
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Text fontSize='20px' fontStyle='normal' fontWeight='500' lineHeight='24px' color="#1D2B2D" mb={3}>Featured Integrations</Text>
                    <Box display="flex" alignItems="center" cursor="pointer" _hover={{ bg: "gray.100" }} px={2} py={2} borderRadius={5}>
                        <Text fontSize="14px" fontWeight="500" color="gray.700" cursor="pointer">
                            View All
                        </Text>
                    </Box>
                </Flex>

                <FeaturedIntegrations />
                <Flex
                    mt='5'
                    mb='3'
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Text fontSize='20px' fontStyle='normal' fontWeight='500' lineHeight='24px' color="#1D2B2D" mb={3}>Stash Use Cases</Text>
                    <Box display="flex" alignItems="center" cursor="pointer" _hover={{ bg: "gray.100" }} px={2} py={2} borderRadius={5}>
                        <Text fontSize="14px" fontWeight="500" color="gray.700" cursor="pointer">
                            View All
                        </Text>
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Dashboard;
