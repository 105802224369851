// src/LoginPage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Stack,
  Divider,
  Text,
  Link,
  InputGroup,
  InputRightElement,
  useToast,
  Image,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import googleLogo from "../../images/Google.svg";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isEmailValidated, setIsEmailValidated] = useState(false);
  const [signUpCode, setSignUpCode] = useState("");
  const toast = useToast();
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/dashboard", { replace: true });
    }
    setIsButtonEnabled(!!email.trim());
  }, [email, user, navigate]);

  const handleClearEmail = () => setEmail("");
  const handleClearSignUpCode = () => setSignUpCode("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      toast({
        title: "Missing Fields",
        description: "Please fill in all fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!emailRegex.test(email)) {
      toast({
        title: "Invalid Email",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // If email is valid, show Sign Up Code input and switch buttons
    setIsEmailValidated(true);
  };

  const handleCreateAccount = (e) => {
    e.preventDefault();
    if (!signUpCode) {
      toast({
        title: "Missing Sign Up Code",
        description: "Please enter the sign-up code.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Proceed with account creation logic
    // toast({
    //   title: "Account Created",
    //   description: "Your account has been successfully created!",
    //   status: "success",
    //   duration: 3000,
    //   isClosable: true,
    // });
  };

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box width='490px'>
        <Heading className='heading' textAlign="start" >
          Sign Up
        </Heading>
        <Text mt='16px' mb='24px' className='subheading'>Enter your email to sign in</Text>
        <form onSubmit={isEmailValidated ? handleCreateAccount : handleSubmit}>
          <Stack spacing={2}>
            <FormControl id="email" isRequired>
              <FormLabel className='formheading' mb='16px'>Email</FormLabel>
              <InputGroup>
                <Input
                  borderRadius='8px'
                  borderColor='#D0D6D7'
                  _hover={{ borderColor: '#566062' }}
                  _focus={{ borderColor: '#020C0E', boxShadow: "0 0 0 1px #020C0E", }}
                  type="email"
                  // placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {email && (
                  <InputRightElement>
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handleClearEmail}
                      variant="unstyled"
                    >
                      <CloseIcon w={3} h={3} />
                    </Button>
                  </InputRightElement>
                )}
              </InputGroup>
            </FormControl>
            {isEmailValidated && (
              <Text className='formtext' color='#394547' mt='24px' mb='24px'>
                We just sent you a temporary sign up code. Please check your
                inbox and add the sign up code below.
              </Text>
            )}

            {isEmailValidated && (
              <FormControl id="signup-code" isRequired>
                <FormLabel className='formheading' mb='16px'>Sign Up Code</FormLabel>
                <InputGroup>
                  <Input
                    type="password"
                    //placeholder="Enter your sign-up code"
                    value={signUpCode}
                    onChange={(e) => setSignUpCode(e.target.value)}
                    borderRadius='8px'
                    borderColor='#D0D6D7'
                    _hover={{ borderColor: '#566062' }}
                    _focus={{ borderColor: '#020C0E', boxShadow: "0 0 0 1px #020C0E", }}
                  />
                  {signUpCode && (
                    <InputRightElement>
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={handleClearSignUpCode}
                        variant="unstyled"
                      >
                        <CloseIcon w={3} h={3} />
                      </Button>
                    </InputRightElement>
                  )}
                </InputGroup>
              </FormControl>
            )}

            {!isEmailValidated ? (
              <Button
                type="submit"
                width="full"
                mt='24px'
                colorScheme="blue"
                borderColor={isButtonEnabled ? '#020C0E' : '#E8EAED'}
                bg={isButtonEnabled ? '#394547' : '#E8EAED'}
                color={isButtonEnabled ? 'white' : '#9BA1A2'}
                _hover={isButtonEnabled ? { bg: '#566062' } : {}}
                isDisabled={!isButtonEnabled}
                onClick={() => navigate('/signup')}
                className='formtext'
              >
                {isButtonEnabled ? 'Sign In' : 'Continue with Email'}
              </Button>
            ) : (
              <Button
                className='formtext'
                type="submit"
                width="full"
                mt='24px'
                mb='24px'
                colorScheme="gray.700"
                bg="#394547"
                color="white"
                _hover={{ bg: "#566062" }}
                onClick={() => navigate('/passwordsetup')}
              >
                Create Account
              </Button>
            )}
            {isEmailValidated && (
              <Text textAlign="center" className='formtext' color='#395A5F'>
                You can also <Link textDecoration="underline"> Continue with SSO </Link>
              </Text>
            )}
            <Stack direction="row" alignItems="center" mt='24px' mb='24px'>
              <Divider color='#E8EAED' />
              <Text className='formtext' color='#A3A0B4' px='8px'>or</Text>
              <Divider color='#E8EAED' />
            </Stack>
            <a href="/.auth/login/google">
              <Button
                width="full"
                variant="outline"
                mb='24px'
                className='formtext'
                color='#394547'
                borderRadius='8px'
                borderColor='#D0D6D7'
                _hover={{ bg: '#566062', borderColor: '#566062' }}
              >
                <Image src={googleLogo} alt="Google" boxSize="20px" mr='8px' />
                Continue with Google
              </Button>
            </a>
          </Stack>
        </form>

        <Text textAlign="center" className='formtext' color='#395A5F' mb='24px'>
          By clicking "Continue with Google or email" above, you acknowledge
          that you have read and understood, and agree to Stash's{" "}
          <Link textDecoration='underline' textUnderlineOffset={3}>Terms & Condition</Link> and <Link textDecoration='underline' textUnderlineOffset={3}>Privacy Policy</Link>.
        </Text>
      </Box>
    </Box>
  );
};

export default Signup;
