import React, {useEffect, useState} from 'react';
import {Box, ChakraProvider, Text} from '@chakra-ui/react';
import theme from "../../theme/theme";
import SelectMode from "./mode/Select";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../auth/AuthContext";
import Extract from "./mode/type/Extract";
import Chat from "./mode/type/Chat";
import Compare from "./mode/type/Compare";
import Search from "./mode/type/Search";

function Deploy() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {user} = useAuth();
    const [stash, setStash] = useState(null);
    const [mode, setMode] = useState('select'); // 'select' or 'preview'
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchStashDetail = async () => {
            try {
                const response = await fetch(`https://stash.azurewebsites.net/api/GetStashDetail?code=GTINonaXAMchVPWkL5rwQ_mZndINfEjXBStCQSmb5CZiAzFu8byAaQ%3D%3D&id=${id}&userId=${user.userId}`, {
                    method: 'GET'
                });
                if (!response.ok) throw new Error('Failed to fetch stash details');
                const stash = await response.json();
                setStash(stash);
            } catch (error) {
                setError(error.message);
            }
        };
        if (user && user.userId) {
            fetchStashDetail();
        } else {
            navigate('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, user, navigate]);

    if (error) {
        return <Text color="red.500">{error}</Text>
    }

    return (
        <ChakraProvider theme={theme}>
            {!stash && (
                <Text>Loading...</Text>
            )} 
            {stash && (
                <Box p={4} alignSelf={"start"} width={"100%"}>
                    {mode === 'select' ? (
                        <SelectMode onSelectMode={(mode) => setMode(mode)} />
                    ) : mode === 'search' ? (
                        <Search onBack={() => setMode('select')} stash={stash} />
                    ) : mode === 'chat' ? (
                        <Chat onBack={() => setMode('select')} stash={stash} />
                    ) : mode === 'extract' ? (
                        <Extract onBack={() => setMode('select')} stash={stash} />
                    ) : (
                        <Compare onBack={() => setMode('select')} stash={stash} />
                    )}
                </Box>
            )}
        </ChakraProvider>
    );
}

export default Deploy;